@use "sass:map";
@use "sass:math";
//$primary: darken(#33cc33, 20%);
$enable-gradients: true;
$primary: #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;
$navy:    #000090 !default;
$aqua:    #00FFFF !default;
$black:   #000000 !default;
$brBlue:  #0000FF !default;
$brGreen: #00FF00 !default;
$brRed:   #FF0000 !default;
$brYellow:#FFFF00 !default;
$brPink:  #FF00FF !default;
$brPurple: #800080 !default;
$brDarkGreen: #008000 !default;

$theme-colors: (
        "primary": $primary,
        "indigo": $indigo,
        "purple": $purple,
        "orange": $orange,
        "pink": $pink,
        "navy": $navy,
        "aqua": $aqua,
        "black": $black,
        "brblue": $brBlue,
        "brgreen": $brGreen,
        "brred": $brRed,
        "bryellow": $brYellow,
        "brpink": $brPink,
        "brpurple": $brPurple,
        "brdarkgreen": $brDarkGreen
);

$hexopacity: 44;
$fc-theme-colors: indigo,
purple,
pink,
navy,
aqua,
black,
brblue,
brgreen,
brred,
bryellow,
brpink,
brpurple,
primary,
info,
secondary,
warning,
danger,
dark,
orange,
success,
brdarkgreen;

@import "~bootstrap4/scss/bootstrap";
//@import "~bootstrap/scss/bootstrap";
@mixin border-opacity($color, $opacity: 0.25) {
  box-shadow: 0 0 0 0.13rem $color;
  box-shadow: 0 0 0 0.13rem rgba($color, $opacity);

}
html, body{
  font-family: 'Ubuntu', verdana;
}
body {
  max-width: 99.99%;
}
body > .container-fluid{
  padding: 0px;
}
.w-20{
  width: 20% !important;
}
#filtrList {
  overflow-y: auto;
}
.h4.border{
  cursor: pointer;
}
.h4.border:hover {
  border-width: 2px !important;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;
}
.filtr-item .h4.border:hover {
  padding-right: 2px !important;
  padding-bottom: 2px !important;
}
body > .card, body > .container-fluid > .card, body > .container-fluid > .card > .card-header.bg-indigo, body > .card > .card-header.bg-indigo{
  border: none;
  border-radius: 0px;
}

@each $fc-theme-color in $fc-theme-colors {
  .border-filter-#{"" + $fc-theme-color} {
    $clr: #{theme-color(#{"" + $fc-theme-color})};
    box-shadow: 0 0 0 0.13rem #{$clr+'44'};
  }
  .border-filter-#{"" + $fc-theme-color}:hover, .border-filter-#{"" + $fc-theme-color}:active {
    $clr: #{theme-color(#{"" + $fc-theme-color})};
    box-shadow: 0 0 0 0.13rem #{$clr};
  }
}
.icon-spacer::before,
.icon-blank::before {
  display: inline-block;
  width: 1em;
  content: ' ';
}